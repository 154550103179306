body,input {
  margin: 0;
  font-family: 'Kanit', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Kanit', sans-serif; 
}
.ant-spin-text { color: #4db6ac; }
.ant-spin-dot-item {
  background-color: #4db6ac;
}