.reward-card-cardline {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  /* background-color: rgba(0, 0, 0, 0); */
  width: 172px;
  height: 235px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content; */
  padding: 0 0;

  border: 4px solid;
}

.reward-card-main {
  display: flex;
  margin-top: 10px;
  padding: 0;
  width: 157px;
  height: 157px;
  /* background-color: green; */
  position: relative;
}

.reward-card-progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-card-avatar {
  /* background-color: rgba(255, 192, 203, 0.5); */

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reward-card-point {
  background-color: skyblue;
  position: absolute;
  bottom: 0px;
  left: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #444444;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 20px;
}
.reward-card-point p {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.reward-card-content {
  /* background-color: aquamarine; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  /* margin: 0.5rem; */
}
.reward-card-content p,
.reward-card-content h1 {
  margin: 0;
}

.reward-card-content p {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.reward-card-content h1 {
  font-size: 36px;
  font-weight: 400;
  line-height: 42px;
}

.reward-card-achieved {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-card-tagStatus {
  position: absolute;
  /* margin: 0; */
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0;

  transform: translate(0, 120%);
  display: flex;
  justify-content: center;
}

.reward-card-tagStatus--v2 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.reward_coin--btn--v2 {
  position: absolute;
  bottom: 0;
  width: 80%;
  /* color: #ccc; */
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  padding: 8px 4px;
  text-align: center;
  border: none;
  border-radius: 20px;
  /* clip-path: polygon(7% 0, 93% 0, 100% 50%, 93% 100%, 7% 100%, 0 50%); */
  transform: translate(0, 70%);
}

.btn-green {
  color: #333;
  background-color: rgba(56, 142, 60, 1);
  background-image: radial-gradient(
    200% 70% at center 20%,
    rgba(129, 199, 132, 1) -30%,
    rgba(76, 175, 80, 1) 49%,
    rgba(56, 142, 60, 1) 50%,
    rgba(56, 142, 60, 1) 150%
  );
  background-repeat: no-repeat;

  transition: background-position-y ease-in-out 250ms;
  transform: translate(0, 70%);
}

.btn-orange {
  /* ORANGE BUTTON */
  color: #444;
  background-color: rgba(245, 124, 0, 1);
  background-image: radial-gradient(
    200% 70% at center 20%,
    rgba(255, 183, 77, 1) -30%,
    rgba(255, 152, 0, 1) 49%,
    rgba(245, 124, 0, 1) 50%,
    rgba(245, 124, 0, 1) 150%
  );
  background-repeat: no-repeat;

  transition: background-position-y ease-in-out 250ms;
  transform: translate(0, 70%);
}

.btn-blue {
  /* BLUE BUTTON */
  color: #333;
  background-color: rgba(25, 118, 210, 1);
  background-image: radial-gradient(
    200% 70% at center 20%,
    rgba(100, 181, 246, 1) -30%,
    rgba(33, 150, 243, 1) 49%,
    rgba(25, 118, 210, 1) 50%,
    rgba(25, 118, 210, 1) 150%
  );
  background-repeat: no-repeat;

  transition: background-position-y ease-in-out 250ms;
  transform: translate(0, 70%);
}

.btn-mint {
  /* GREEN MINT COLOR */
  color: #333;
  background-color: rgba(65, 174, 169, 1);
  background-image: radial-gradient(
    200% 70% at center 20%,
    rgba(108, 214, 209, 1) -30%,
    rgba(90, 200, 195, 1) 49%,
    rgba(65, 174, 169, 1) 50%,
    rgba(65, 174, 169, 1) 150%
  );
  background-repeat: no-repeat;

  transition: background-position-y ease-in-out 250ms;
  transform: translate(0, 70%);
}
/* .reward_coin--btn--v2:hover {
  background-position-y: -50px;
  color: #fff;
} */

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
