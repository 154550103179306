* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .container {
    position: relative;
    width: 100%;
    /* background-color: #fff; */
    min-height: 1000px;
    height: 100vh;
    overflow: hidden;
  }
  
  .forms-container {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;
  }
  
  .signin-signup {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 75%;
    width: 50%;
    transition: 1s 0.7s ease-in-out;
    display: grid;
    grid-template-columns: 1fr;
    z-index: 5;
  }
  
  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0rem 5rem;
    transition: all 0.2s 0.7s;
    overflow: hidden;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  
  form.sign-up-form {
    opacity: 0;
    z-index: 1;
  }
  
  form.sign-in-form {
    z-index: 2;
  }
  
  .title {
    font-size: 30px !important;
    color: #444 !important;
    margin-bottom: 20px !important;
  }
  
  .input-field {
    max-width: 400px;
    width: 100%;
    background-color: #ffffff;
    margin: 5px 0;
    height: 55px;
    border-radius: 30px;
    display: grid;
    grid-template-columns: 15% 85%;
    padding: 0 0.4rem;
    position: relative;
  }

  .input-field i {
    text-align:center;
    line-height: 55px;
    color: #52575d;
    transition: 0.5s;
    font-size: 1.1rem;
  }
  
  .input-textField {
    background: none ;
    outline: none ;
    border: none ;
    line-height: 1;
    font-weight: 600;
    font-size: 1.1rem;
    color: #333;
  }
  
  .input-textField ::placeholder {
    color: #aaa;
    font-weight: 500;
  }
  
  .btn {
    width: 150px;
    background-color: #16a596 !important;
    border: none !important;
    outline: none !important;
    height: 50px !important;
    border-radius: 50px !important;
    color: #fff !important;
    text-transform: uppercase !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    margin: 8px 0 !important;
    cursor: pointer !important;
    transition: 0.5s !important;
  }
  
  /* .btn:hover {
    background-color: #214252!;
  } */
  .panels-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  
  .container:before {
    content: "";
    position: absolute;
    height: 2000px;
    width: 2000px;
    top: 10%;
    right: 50%;
    transform: translateY(-50%);
    background-image: linear-gradient(-45deg, #16a596 0%, #9ad3bc 100%);
    transition: 1.8s ease-in-out;
    border-radius: 50% ;
    z-index: 10 !important;
  }
  
  .panel {
    display: flex ;
    flex-direction: column ;
    align-items: flex-end ;
    justify-content: space-around ;
    text-align: center ;
    z-index: 10;
  }
  
  .left-panel {
    pointer-events: all;
    padding: 1rem 20% 2rem 10% ;
    margin-bottom: 50%;
  }
  
  .right-panel {
    pointer-events: none;
    padding: 1rem 20% 1rem 10%;
    margin-bottom: 50%;
  }
  
  .panel .content {
    color: #fff !important;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }
  .imagelogo {
    height: 150px;
  }

  .text3 {
    color: #fff !important;
    font-weight: 600 !important;
    line-height: 1.5 !important;
    font-size: 30px !important;
  }
  
  .textboby  {
    margin-top: -15px !important;
    color: #fff !important;
    font-size: 0.95rem !important;
    padding: 0.7rem 0 !important;
  }

  .btn-transparent {
    margin: 0 !important;
    background: none !important;
    border: 2px solid #fff !important;
    border-radius: 30px !important;
    width: 130px !important;
    height: 41px !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
  }
  
  .right-panel .image,
  .right-panel .content {
    transform: translateX(800px);
  }
  
  /* ANIMATION */
  
  .container.sign-up-mode:before {
    transform: translate(100%, -50%);
    right: 52%;
  }
  
  .container.sign-up-mode .left-panel .image,
  .container.sign-up-mode .left-panel .content {
    transform: translateX(-800px);
  }
  
  .container.sign-up-mode .signin-signup {
    left: 25%;
  }
  
  .container.sign-up-mode form.sign-up-form {
    opacity: 1;
    z-index: 2;
  }
  
  .container.sign-up-mode form.sign-in-form {
    opacity: 0;
    z-index: 1;
  }
  
  .container.sign-up-mode .right-panel .image,
  .container.sign-up-mode .right-panel .content {
    transform: translateX(0);
  }
  
  .container.sign-up-mode .left-panel {
    pointer-events: none;
  }
  
  .container.sign-up-mode .right-panel {
    pointer-events: all;
  }

  @media screen and (max-width: 1440px) {
    .container {
      min-height: 1200px;
      height: 100vh;
    }
    .left-panel {
      margin-bottom: 60%;
    }
    .right-panel {
      margin-bottom: 60%;
    }
  }

  @media screen and (max-width: 1024px) {
   
    .signin-signup {
      width: 100% !important;
      top: 60% !important;
      transform: translate(-50%, -100%) !important;
      transition: 1s 0.8s ease-in-out !important;
    }
    .container.sign-up-mode .signin-signup {
      top: 80% !important;
      transform: translate(-50%, 0);
    }
    .left-panel {
      padding: 1rem 20% 20rem 10% ;
      margin-bottom: 40% ;
    }
    
    .right-panel {
      padding: 1rem 10% 20rem 5%;
      margin-bottom: 40% ;
    }
   
  }

  @media screen and (max-width: 870px) {
    .signin-signup {
      width: 100% !important;
      top: 60% !important;
      transform: translate(-50%, -100%) !important;
      transition: 1s 0.8s ease-in-out !important;
    }
    .signin-signup,
    .container.sign-up-mode .signin-signup {
      left: 50%;
    }
    .panels-container {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 2fr 1fr;
    }
    .imagelogo {
      height: 100px;
    }
    .panel {
      flex-direction: row ;
      justify-content: space-around;
      align-items: center;
      padding: 1rem 5%;
      grid-column: 1 / 2;
    }
    .right-panel {
      grid-row: 2 / 4 ;
      margin-top: 42%;
    }
    .left-panel {
      grid-row: 1 / 2;
      margin-top: 10%;
    }
    .panel .content {
      padding-right: 50%;
      transition: transform 0.9s ease-in-out;
      transition-delay: 0.8s;
    }
    .text3{
      font-size: 25px !important;
    }
    .textboby  {
      font-size: 0.5rem;
      padding: 0.5rem 0;
    }
    .btn.transparent {
      width: 100px;
      height: 35px;
      font-size: 0.5rem;
    }
    .container:before {
      width: 1000px;
      height: 1000px;
      transform: translateX(-50%);
      left: 20%;
      bottom: 65%;
      right: initial;
      top: initial;
      transition: 2s ease-in-out;
    } 
    .container.sign-up-mode:before {
      transform: translate(-50%, 100%);
      bottom: 25%;
      right: initial;
    }
    .container.sign-up-mode .left-panel .image,
    .container.sign-up-mode .left-panel .content {
      transform: translateY(-500px);
    }
    .container.sign-up-mode .right-panel .image,
    .container.sign-up-mode .right-panel .content {
      transform: translateY(0);
    }
    .right-panel .image,
    .right-panel .content {
      transform: translateY(500px);
    }
    .container.sign-up-mode .signin-signup {
      top: 75% !important;
      transform: translate(-50%, 0);
    }
  }
  
  @media screen and (max-width: 768px) {
    .container {
      min-height: 1200px;
      height: 1024px;
    }
    .container.sign-up-mode .signin-signup {
      top: 78% !important; 
      transform: translate(-50%, 0);
    }
    .right-panel {
      margin-top: 60%;
    }
    .left-panel {
      margin-top: 13%;
    }
    .container.sign-up-mode:before {
      transform: translate(-50%, 100%);
      bottom: 20%;
      right: initial;
    }
  }
  
  @media screen and (max-width: 570px) {
    .signin-signup {
      width: 100% !important;
      top: 60% !important;
      transform: translate(-50%, -100%) !important;
      transition: 1s 0.8s ease-in-out !important;
    }
    .container.sign-up-mode .signin-signup {
      top: 78% !important; 
      transform: translate(-50%, 0);
    }
    .btn.transparent {
      width: 110px;
      height: 35px;
      font-size: 0.7rem;
    }
    .panel .content {
      padding: 0.5rem 0.5rem;
    }
    .right-panel {
     margin-top: 95%;
    }
    .left-panel {
      margin-top: 8%;
    }
    .container {
      padding: 1rem;
    }
    .container:before {
      bottom: 70%;
      left: 50%;
    }
    .container.sign-up-mode:before {
      bottom: 20%;
      left: 50%;
    }
  }
  @media (max-width: 540px) {
    .container {
      min-height: 1150px;
      height: 730px;
    }
    .container.sign-up-mode .signin-signup {
      top: 78% !important; 
      transform: translate(-50%, 0);
    }
    .container:before {
      bottom: 69%;
      left: 50%;
    }
    .right-panel {
      margin-top: 100%;
    }
    .left-panel {
      margin-top: 12%;
    }
    .container.sign-up-mode:before {
      bottom: 20%;
      left: 50%;
    }
  }

  @media screen and (max-width: 425px) {
    .container {
      min-height: 1000px;
      height: 1178px;
    }
    .container.sign-up-mode .signin-signup {
      top: 78% !important; 
      transform: translate(-50%, 0);
    }
    form {
      padding: 0 0.5rem !important;
    }
    .panel .content {
      padding: 1rem 1rem !important;
    }
    .right-panel {
      margin-top: 135%;
    }
    .left-panel {
      margin-top: 20%;
    }
    .container:before {
      bottom: 70%;
      left: 50%;
    }
    .container.sign-up-mode:before {
      bottom: 20%;
      left: 50%;
    }
  }
  @media screen and (max-width: 414px) {
    .container {
      min-height: 1150px;
      height: 736px;
    }
    .container.sign-up-mode .signin-signup {
      top: 80% !important; 
      transform: translate(-50%, 0);
    }
    .imagelogo {
      height: 80px;
    }
    .text3{
      font-size: 22px !important;
    }
    .btn-transparent {
      width: 120px !important;
      height: 40px !important;
      font-weight: 600 !important;
      font-size: 16px !important;
    }
    .right-panel {
      margin-top: 138%;
      padding: 50px 10px 280px 10px;
    }
    .left-panel {
      margin-top: 15%;
    }
    .container.sign-up-mode:before {
      bottom: 18%;
      left: 50%;
    }
  }

  @media screen and (max-width: 411px) {
    .container {
      min-height: 1150px;
      height: 900px;
    }
    .container.sign-up-mode .signin-signup {
      top: 80% !important; 
      transform: translate(-50%, 0);
    }
    .container:before {
      bottom: 70%;
      left: 50%;
    }
    .container.sign-up-mode:before {
      bottom: 18%;
      left: 50%;
    }
    .right-panel {
      margin-top: 150%;
      padding: 5px 10px 300px 10px;
    }
    .left-panel {
      margin-top: 15%;
    }
  }

  @media screen and (max-width: 375px) {
    .container {
      min-height: 1150px;
      height: 812px;
    }
    .left-panel {
      margin-top: 18%;
    }
    .right-panel {
      margin-top: 168%;
      padding: 5px 10px 250px 10px;
    }
    .container {
      padding: 1.0rem !important;
    }
    .container:before {
      bottom: 70%;
      left: 50%;
    }
    .container.sign-up-mode:before {
      bottom: 18%;
      left: 50%;
    }
  }

  @media screen and (max-width: 360px) {
    .container {
      min-height: 1100px;
      height: 640px;
    }
    .signin-signup {
      width: 100%;
      top: 55% !important;
      transform: translate(-50%, -100%);
      transition: 1s 0.8s ease-in-out;
    }
    .container.sign-up-mode .signin-signup {
      top: 82% !important; 
      transform: translate(-50%, 0);
    }
    .imagelogo {
      display: none;
    }
    .container:before {
      bottom: 75%;
      left: 50%;
    }
    .container.sign-up-mode:before {
      bottom: 15%;
      left: 50%;
    }
    .panel {
      padding: none;
    }
    .right-panel {
      margin-top: 185% !important;
      padding: 5px 10px 150px 10px;
    }
    .left-panel {
      margin-top: 15% !important;
      padding: 30px 0px;
    }
    .title {
      font-size: 25px !important;
      color: #444 !important;
      margin-bottom: 15px !important;
    }
  }
  @media screen and (max-width: 320px) {
    .container {
      min-height: 1100px;
      height: 586px;
    }
    .signin-signup {
      width: 100%;
      top: 50% !important;
      transform: translate(-50%, -100%);
      transition: 1s 0.8s ease-in-out;
    }
    .container.sign-up-mode .signin-signup {
      top: 82% !important; 
      transform: translate(-50%, 0);
    }
    .right-panel {
      margin-top: 215% !important;
      padding: 0px 10px 100px 10px !important;
    }
    .left-panel {
      margin-top: 20% !important;
    }
  }

  @media screen and (max-width: 280px) {
    .container {
      min-height: 1100px;
      height: 653px;
    }
    .signin-signup {
      width: 100% !important;
      top: 55% !important;
      transform: translate(-50%, -100%) !important;
      transition: 1s 0.8s ease-in-out !important;
    }
    .right-panel {
      margin-top: 240% !important;
    }
    .left-panel {
      margin-top: 30% !important;
    }
    .container:before {
      bottom: 75%;
      left: 50%;
    }
    .container.sign-up-mode:before {
      bottom: 15%;
      left: 50%;
    }
  }