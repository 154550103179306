.alertBanner{
    border: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    resize: vertical;
    max-height: 85px;
}
.textpage{
    font-size: 16px !important;
    color: white;
}
.text01{
    margin-top: 15px !important;
    color: #213E3B;
    font-size: 60px !important;
}
.text02{
    color: #213E3B;
}
.text03{
    margin-top: 10px !important; 
    font-size: 22px !important;
}
.text04{
    font-size: 16px !important;
}
.text05{
    margin-top: 10px !important;
    font-size: 14px !important;
}
.buttonHome{
   margin-top: 20px !important;
   font-size: 20px !important; 
   border-radius: 5px !important;
   width: 200px !important;
}
.textbody01{
    font-size: 24px !important;
    padding-top: 15px !important;
    color: #213E3B;
}
.textbody02{
    font-size: 16px !important;
    line-height: 1.5 !important;
    text-align: justify;
    text-indent: 10%;
    padding-top: 5px !important;
}
.vi{
    padding-top:10px !important;
    overflow: hidden !important;
    position: relative !important;
}
.video{
    width: 100% !important;
    height: 280px !important;
    background-position: center !important;
}
.titleHome{
    color: #213E3B;
    padding-top: 5px;
}
.img{
    width: 80% !important;
    height: 100% !important;
    display: inline-flex;
    vertical-align: baseline;
}
.carouimg{
  position: relative;
  flex-basis: auto;
  display: flex;
  justify-content: center;
}
.paper{
    background-color:#EEEEEE !important;
    height:auto !important;
    padding: 20px 20px 20px 20px !important;
    border-radius: 10px !important;
}
.title01{
    color:#fff;
    padding: 10px;
}
.textbody03{
    color:#fff;
    font-size: 15px !important;
}
.titleQA{
  font-size: 18px !important;
}
.textQA{
  font-size: 16px !important;
}

@media screen and (max-width: 1024px){
    /* .text05{
        font-size: 15px !important;
        color: red;
        margin-top: 10px !important;
    } */
    .video{
        height: 260px !important;
    }
    .textbody03{
        font-size: 16px !important;
    }
}
@media screen and (max-width: 768px){
    .textbody01{
        font-size: 22px !important;
    }
    .textbody02{
        font-size: 16px !important;
    }   
    .vi{
        width: 80% !important;
        margin: auto !important;
    }
    .titleHome{
        font-size: 22px !important;
    }
    .titleQA{
        font-size: 16px !important;
      }
      .textQA{
        font-size: 14px !important;
      }
}
@media screen and (max-width: 540px){
    .text01{
        font-size: 40px !important;
    }
    .img{
        width: 100% !important;
    }
    .textbody03{
        font-size: 14px !important;
    }
    .video{
        height: 220px !important;
    }
}

@media screen and (max-width: 425px){
.textpage{
    font-size: 14px !important;
}
.text01{
    margin-top: 10px !important;
    font-size: 30px !important;
}
.text02{
    font-size: 20px !important;
}
.text03{
    margin-left: 10px !important;
    margin-top: 5px !important; 
    font-size: 16px !important;
}
.text04{
    margin-left: 10px !important;
    font-size: 16px !important;
}
.text05{
    text-align: center;
}
.buttonHome{
   margin-top: 15px !important;
   font-size: 15px !important; 
   width: 180px !important;
}
.textbody01{
    font-size: 20px !important;
    text-align: center;
}
.textbody02{
    font-size: 14px !important;
}
.vi{
    margin-top: -20px !important;
    width: 100% !important;
    height: 100% !important;
}
.titleHome{
    font-size: 20px !important;
    text-align: center !important;
}
.title01{
    font-size: 18px !important;
}
.textbody03{
    text-align: justify !important;
}
.textbody04{
    font-size: 14px !important;
}
}

@media screen and (max-width: 375px){
    .text01{
        font-size: 25px !important;
    }
    .text02{
        font-size: 18px !important;
    }
    .text03{
        font-size: 16px !important;
    }
    .text04{
        font-size: 16px !important;
    }
    .text05{
        font-size: 12px !important;
    }
    .textbody02{
        text-indent: 15%;
    }
    .video{
        height: 200px !important;
    }
    .title01{
        font-size: 16px !important;
    }
    .textbody03{
        font-size: 12px !important;
    }
    .titleQA{
        font-size: 14px !important;
      }
    .textQA{
        font-size: 12px !important;
    }
}

@media screen and (max-width: 320px){
    .textpage{
        font-size: 12px !important;
    }
    .text01{
        margin-top: 10px !important;
    }
    .text02{
        font-size: 16px !important;
    }
    .text03{
        margin-top: 0px !important; 
        font-size: 14px !important;
    }
    .text04{
        font-size: 14px !important;
    }
    .text05{
        font-size: 11px !important;
    }
    .video{
        height: 180px !important;
    }
     .textbody01{
        font-size: 16px !important;
    }
    .textbody02{
        text-indent: 15%;
        font-size: 12px !important;
    }
    .title01{
        font-size: 14px !important;
    }
    .titleQA{
        font-size: 12px !important;
      }
}
@media screen and (max-width: 280px){
    .text01{
        font-size: 18px !important;
    }
    .text02{
        font-size: 14px !important;
    }
    .text03{
        margin-top: 0px !important; 
        font-size: 12px !important;
    }
    .text04{
        font-size: 12px !important;
    }
}
    