.coins_container-card {
  /* background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(67, 133, 127, 0.7)),
    url(./../../images/homeBG.png) no-repeat right top; */

  background-size: 100%;
}

.reward_coin {
  margin: 10px;
  /* padding: 20px; */
  position: relative;
}

.reward_coin :hover {
  cursor: pointer;
}

.reward-container_link:hover {
  cursor: pointer;
}

.reward_coin_f44336 {
  background: linear-gradient(
    -45deg,
    #f4a235,
    #f44336,
    #fdf4ab,
    #f44336,
    #f4a235
  );
}

.reward_coin_ffeb3b {
  background: linear-gradient(
    -45deg,
    #80ff3b,
    #ffeb3b,
    #fdf4ab,
    #80ff3b,
    #ffeb3b
  );
}

.reward_coin_e91e63 {
  background: linear-gradient(
    -45deg,
    #e91e63,
    #f0749e,
    #fdcfde,
    #c574ef,
    #8774ef
  );
}

.reward_coin_9c27b0 {
  background: linear-gradient(
    -45deg,
    #9c27b0,
    #d581e3,
    #ffa8c6,
    #f15186,
    #b22bf5
  );
}

.reward_coin_673ab7 {
  /* display: flex;
  justify-content: center; */
  background: linear-gradient(
    -45deg,
    #b63aa9,
    #673ab7,
    #d98a94,
    /* #a78ada, */ #b63aa9,
    #673ab7
  );
}

.reward_coin_3f51b5 {
  background: linear-gradient(
    -45deg,
    #3f51b5,
    #6776ca,
    #41bc6d,
    #4254bc,
    #6d41bc
  );
}

.reward_coin_2196f3 {
  background: linear-gradient(
    -45deg,
    #b221f2,
    #2196f3,
    #f4fda5,
    #2196f3,
    #b221f2
  );
}

.reward_coin_03a9f4 {
  background: linear-gradient(
    -45deg,
    #03a9f4,
    #02f4d4,
    #f4fda5,
    #03a9f4,
    #30f402
  );
}

.reward_coin_00bcd4 {
  background: linear-gradient(
    -45deg,
    #00bcd4,
    #1700d4,
    #00e2ff,
    #1700d4,
    #00bcd4
  );
}

.reward_coin_009688 {
  background: linear-gradient(
    -45deg,
    #009688,
    #00665c,
    #33ffeb,
    #00665c,
    #009688
  );
}

.reward_coin_4caf50 {
  background: linear-gradient(
    -45deg,
    #4caf50,
    #2e6a30,
    #0be84a,
    #2e6a30,
    #4caf50
  );
}

.reward_coin_8bc34a {
  background: linear-gradient(
    -45deg,
    #4083be,
    #8bc34a,
    #09ebeb,
    #8bc34a,
    #4083be
  );
}

.reward_coin_cddc39 {
  background: linear-gradient(
    -45deg,
    #cddc39,
    #39db48,
    #e9efa8,
    #cddc39,
    #39db48
  );
}

.reward_coin_ffc107 {
  background: linear-gradient(
    -45deg,
    #ffc107,
    #ff4406,
    #fc3,
    #ffc107,
    #ff4406 /* #ffe599 */
  );
}

.reward_coin_ffc107 {
  background: linear-gradient(
    -45deg,
    #ffc107,
    #ff4406,
    #fc3,
    #ffc107,
    #ff4406 /* #ffe599 */
  );
}

.reward_coin_ff9800 {
  background: linear-gradient(
    -45deg,
    #ff9800,
    #ffe900,
    #fae7af,
    #ff9800,
    #ffe900 /* #ffe599 */
  );
}

.reward_coin_ff5722 {
  background: linear-gradient(
    -45deg,
    #ff5722,
    #ffc521,
    #fae7af,
    #ff5722,
    #ffc521 /* #ffe599 */
  );
}

.reward_coin_795548 {
  background: linear-gradient(
    -45deg,
    #795548,
    #b28c7f,
    #fae7af,
    #795548,
    #b28c7f /* #ffe599 */
  );
}

.reward_coin_607d8b {
  background: linear-gradient(
    -45deg,
    #607d8b,
    #3e515a,
    #b094b6,
    #607d8b,
    #3e515a /* #ffe599 */
  );
}

.active {
  background-size: 120% 120%;
  animation: gradient 1.5s ease infinite;
}

.reward-infoandstatus {
  /* margin: 10px; */
  /* height: 100v; */
  overflow-y: scroll;
  background: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(176, 182, 120, 0.8)
    ),
    url(./../../images/parkrun-anywhere.jpeg) no-repeat fixed left center;

  background-size: 220%;
  /* font-family: inherit; */
}

.reward-infoandstatus h2 {
  font-weight: 700;
}
.reward-infoandstatus-card-detail {
  background: #82e9de99;
  margin: 15px;
  border-radius: 0 50px;
  padding: 20px;
  box-shadow: 10px 10px 30px #646464;
}

.reward-infoandstatus-card-detail h3 {
  text-decoration: underline;
}
.reward-infoandstatus-address-form {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  box-shadow: 10px 10px 30px #646464;
}

.reward-status-timeline {
  width: 300px;
}

.reward-point-background {
  /* height: 100vh; */
  max-width: 1200;
  /* overflow-y: hidden; */
  /* background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(67, 133, 127, 0.7)), */
  /* url(./../../images/homeBG.png) no-repeat right top fixed; */

  /* background-size: 120%; */

  display: grid;
  grid-template-rows: repeat(1, 1fr);
  /* grid-template-columns: repeat(2, 1fr); */
}

.reward-point-container {
  max-width: 1200px;
  background-color: #fff;
  margin: 120px auto;
  border-radius: 10px;
  /* margin-bottom: 0px; */

  padding: 0;
}
.reward-point-card {
  margin: 20px 0;
}

.reward_coin--btn {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  color: #444;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  padding: 8px 4px;
  text-align: center;
  border: none;
  border-radius: 20px;
  /* clip-path: polygon(7% 0, 93% 0, 100% 50%, 93% 100%, 7% 100%, 0 50%); */
  /* background-color: rgba(65, 174, 169, 1);
  background-image: radial-gradient(
    200% 70% at center 20%,
    rgba(108, 214, 209, 1) -30%,
    rgba(90, 200, 195, 1) 49%,
    rgba(65, 174, 169, 1) 50%,
    rgba(65, 174, 169, 1) 150%
      /* rgba(22, 18, 19, 1) 50%,
    rgba(22, 18, 19, 1) 150% 
   ); 
  background-repeat: no-repeat; */

  transition: background-position-y ease-in-out 250ms;
  transform: translate(4%, 20%);
}

.btn-green {
  color: #333;
  background-color: rgba(56, 142, 60, 1);
  background-image: radial-gradient(
    200% 70% at center 20%,
    rgba(129, 199, 132, 1) -30%,
    rgba(76, 175, 80, 1) 49%,
    rgba(56, 142, 60, 1) 50%,
    rgba(56, 142, 60, 1) 150%
  );
  background-repeat: no-repeat;

  transition: background-position-y ease-in-out 250ms;
  transform: translate(4%, 20%);
}

.btn-orange {
  /* ORANGE BUTTON */
  color: #444;
  background-color: rgba(245, 124, 0, 1);
  background-image: radial-gradient(
    200% 70% at center 20%,
    rgba(255, 183, 77, 1) -30%,
    rgba(255, 152, 0, 1) 49%,
    rgba(245, 124, 0, 1) 50%,
    rgba(245, 124, 0, 1) 150%
  );
  background-repeat: no-repeat;

  transition: background-position-y ease-in-out 250ms;
  transform: translate(4%, 20%);
}

.btn-blue {
  /* BLUE BUTTON */
  color: #333;
  background-color: rgba(25, 118, 210, 1);
  background-image: radial-gradient(
    200% 70% at center 20%,
    rgba(100, 181, 246, 1) -30%,
    rgba(33, 150, 243, 1) 49%,
    rgba(25, 118, 210, 1) 50%,
    rgba(25, 118, 210, 1) 150%
  );
  background-repeat: no-repeat;

  transition: background-position-y ease-in-out 250ms;
  transform: translate(4%, 20%);
}

.btn-mint {
  /* GREEN MINT COLOR */
  color: #333;
  background-color: rgba(65, 174, 169, 1);
  background-image: radial-gradient(
    200% 70% at center 20%,
    rgba(108, 214, 209, 1) -30%,
    rgba(90, 200, 195, 1) 49%,
    rgba(65, 174, 169, 1) 50%,
    rgba(65, 174, 169, 1) 150%
  );
  background-repeat: no-repeat;

  transition: background-position-y ease-in-out 250ms;
  transform: translate(0, 70%);
}

/* .reward_coin--btn:hover {
  background-position-y: -50px;
  color: #fff;
} */

.flip-in-ver-right {
  -webkit-animation: flip-in-ver-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: flip-in-ver-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-26 13:25:34
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-ver-right
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-ver-right {
  0% {
    -webkit-transform: rotateY(-80deg);
    transform: rotateY(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
  }
}
@keyframes flip-in-ver-right {
  0% {
    -webkit-transform: rotateY(-80deg);
    transform: rotateY(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (min-width: 1200px) {
  .reward-point-container {
    min-width: 1200px;
  }
}

@media (max-width: 1200px) {
  .reward-point-background {
    margin: auto 20px;
  }
}

@media (max-width: 960px) {
  .reward-point-background {
    margin: auto 20px;
  }
}
