.titleEvent{
    color: inherit !important;
    padding-bottom: 5px !important;
}
.textEvent02{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 18px !important;
}
.textEvent03{
    font-size: 14px !important;
    padding-bottom: 2px !important;
}
.textEvent05{
    font-size: 14px !important;
    padding-bottom: 2px !important;
    text-indent: 15px;
}
 .iconevent{
     margin-left: 25% !important;
     margin-top: 10px;
     height: 180px;
 }
.paperEvent{
    width: 100% !important;
    height:300px !important;
    border-style: solid;
    border-color: #CAE4E5;
}
@media screen and (max-width: 425px){
    .titleEvent{
        font-size: 20px !important;
    }
    .textEvent02{
        font-size: 16px !important;
    }
    .textEvent03{
        font-size: 12px !important;
    }
    .textEvent05{
        font-size: 12px !important;
    }
    .paperEvent{
        height:250px !important;
    }
}