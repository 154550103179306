.reward-infoandstatusV2 {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  gap: 50px;
  /* background: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(176, 182, 120, 0.8)
    ),
    url(./../../images/parkrun-anywhere.jpeg) no-repeat fixed left center; */

  background-size: 220%;
}

.reward-infoandstatusV2-container {
  max-width: 1200px;
  margin: auto;
  margin-top: 100px;
}

.reward-infoandstatus_layout {
  margin: 0 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  grid-auto-rows: minmax(100px, auto);
  /* grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto); */
}

.grid-option-1row {
  grid-template-rows: 1fr;
}
.grid-option-autorow {
  grid-auto-rows: minmax(100px, auto);
}

.grid-layout {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr;
  margin: 0;
  padding: 0;
}

.reward-infoandstatus-card-detailV2 {
  background: #82e9de99;
  /* margin: 15px; */
  border-radius: 0 50px 50px 0;
  padding: 32px 0;
  /* overflow: hidden; */
  /* box-shadow: 1px 1px 10px #646464; */
}

.reward-infoandstatus-interface {
  width: 100%;
  grid-column: 1/4;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.reward-infoandstatus-interface--box {
  width: 100%;
  padding: 50px;
  display: flex;
  justify-content: center;
}

.reward-infoandstatus-interface-subBox {
  width: 75%;
}

.reward-infandstatus-rewardinfo {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  grid-column: 1/-1;
  grid-row: 2/4;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: minmax(100px, auto);
  gap: 20px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.reward-infandstatus-rewardinfo-leftCol {
  grid-row: 1/3;
}

.reward-infandstatus-rewardinfo-roleActivity {
  grid-column: 2/-1;
  padding: 0 20px;
}

.reward-infandstatus-rewardinfo-rewardImg {
  padding: 0 20px;
}
/* @media (max-width: 800) {
    
} */

@media (min-width: 1300px) {
  .reward-infoandstatusV2-container {
    width: 1200px;
  }
}

@media (max-width: 800px) {
  .reward-infandstatus-rewardinfo {
    grid-template-columns: repeat(2, 1fr);
  }

  .reward-infandstatus-rewardinfo-leftCol {
    grid-row: 1/4;
  }
  .reward-infandstatus-rewardinfo-roleActivity {
    grid-column: 2/-1;
  }
  .reward-infandstatus-rewardinfo-rewardImg {
    grid-row: 2/3;
  }
}

@media (max-width: 600px) {
  .reward-infoandstatus-card-detailV2 {
    border-radius: 0 0 50px 50px;
  }
  .reward-infandstatus-rewardinfo {
    grid-template-columns: repeat(1, 1fr);
  }

  .reward-infandstatus-rewardinfo-leftCol {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .reward-infandstatus-rewardinfo-roleActivity {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .reward-infandstatus-rewardinfo-rewardImg {
    grid-column: 1/2;
    grid-row: 3/4;
  }

  .reward-infoandstatus-interface-subBox {
    width: 100%;
  }
}
